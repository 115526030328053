import React from 'react'
import { Button, Form, FormGroup, Input } from 'reactstrap';
import MyDatePicker from 'react-datepicker';
import ReactGA from 'react-ga';
import Select from 'react-select'
import { GOOGLE_ANALYTICS_API } from '../constants';

import 'react-datepicker/dist/react-datepicker.css';

const options =
    [
        { value: 1, label: 'EV1' },
        { value: 2, label: 'EV2' },
    ];
const options2 =
    [
        { value: 4, label: 'Eastview 1' },
        { value: 5, label: 'Eastview 2' },
        { value: 6, label: 'Eastview 3' },
        { value: 7, label: 'Eastview 4' },
        { value: 8, label: 'Eastview 5' },
    ];

class ConferenceForm extends React.Component {
    state = {
        id: 0,
        eventDate: '',
        eventTime: '',
        eventName: '',
        location: '',
        result: '',
        score: '',
        userName: '',
        listType: '',
        teamChoices: [],
        selectedTeam: null,
        team: null
    }

    componentDidMount() {
        this.setState({ listType: this.props.listType });
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview('/' + this.state.listType + '/Edit');

        if (this.props.listType === "Conference") {
            console.log("Setting conf teams...");
            this.setState({ teamChoices: options });
        } else {
            console.log("Setting lichess teams...");
            this.setState({ teamChoices: options2 });
        }

        if (this.props.event) {
            const { id, eventName, location, result, score } = this.props.event;
            this.setState({ id, eventName, location, result, score });

            if (this.props.event.teamId != null) {
                var curTeamId = this.props.event.teamId
                //console.log("Team ID: ", curTeamId);
                this.setState({ team: curTeamId });

                if (this.props.listType === "Conference") {
                    this.setState({ selectedTeam: options.find(x => x.value === parseInt(curTeamId)) });
                } else {
                    this.setState({ selectedTeam: options2.find(x => x.value === parseInt(curTeamId)) });
                }
            }


            this.setState({
                eventDate: Date.parse(this.props.event.eventDate),
                eventTime: Date.parse(this.props.event.eventTime),
                userName: this.props.userName
            });
        }
    }

    onChange = e => {
        console.log(e.target.name + " -- " + e.target.value);
        this.setState({ [e.target.name]: e.target.value })
    }

    handleTeamChange = opt => {
        console.log(opt.value + " -- " + opt.label);
        var myTeamId = opt.value;
        if (opt == null)
            this.setState({ team: null });
        else
            this.setState({ team: myTeamId });
        //this.setState({ selectedTeam: opt });
        //console.log("New Team ID: " + this.state.team + " ... myTeamId=" + myTeamId);
        if (this.state.listType === "Conference") {
            this.setState({ selectedTeam: options.find(x => x.value === Number(myTeamId)) });
        } else {
            this.setState({ selectedTeam: options2.find(x => x.value === Number(myTeamId)) });
        }
    }

    handleDateChange = date => {
        //console.log(date);
        this.setState({ eventDate: date });
    };

    handleTimeChange = date => {
        //console.log(date);
        this.setState({ eventTime: date });
    };

    submitNew = e => {
        //console.log(this.state.eventDate);
        e.preventDefault();
        var mySeason = 12;
        if (this.state.listType === "Conference")
            mySeason = 11;
        fetch('api/Events', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventDateUnixTimestamp: this.state.eventDate.toString(),
                eventTimeUnixTimestamp: this.state.eventTime.toString(),
                eventName: this.state.eventName,
                location: this.state.location,
                result: this.state.result,
                teamId: Number(this.state.team),
                userName: this.state.userName,
                score: this.state.score,
                seasonId: mySeason
            })
        })
            .then(res => res.json())
            .then(event => {
                this.props.addToState(event);
                this.props.toggle();
            })
            .catch(err => console.log(err));
    }

    submitEdit = e => {
        e.preventDefault();
        console.log("date: ", this.state.eventDate);
        console.log("time: ", this.state.eventTime);
        console.log("team: ", this.state.team);
        var mySeason = 8;
        if (this.state.listType === "Conference")
            mySeason = 7;
        fetch(`api/Events/${this.state.id}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventDateUnixTimestamp: this.state.eventDate.toString(),
                eventTimeUnixTimestamp: this.state.eventTime.toString(),
                eventName: this.state.eventName,
                location: this.state.location,
                result: this.state.result,
                teamId: Number(this.state.team),
                userName: this.state.userName,
                score: this.state.score,
                seasonId: mySeason
            })
        })
            .then(() => {
                this.props.toggle();
                this.props.updateState(this.state.id);
            })
            .catch(err => console.log(err));
    }

    render() {
        let showExtras = false;
        if (this.state.listType === "Conference") {
            showExtras = true;
        }

        return <Form onSubmit={this.props.event ? this.submitEdit : this.submitNew} >
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Event Name:</span>
                </div>
                <Input type="text" required name="eventName" onChange={this.onChange} value={this.state.eventName === null ? '' : this.state.eventName} />
            </FormGroup>
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Date:</span>
                </div>
                <div>
                    <MyDatePicker name="eventDate"
                        required
                        className="form-control"
                        selected={this.state.eventDate}
                        onChange={this.handleDateChange} />
                </div>
            </FormGroup>
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Time:</span>
                </div>
                <MyDatePicker name="eventTime"
                    AutoCompleteType="disabled"
                    className="form-control"
                    selected={this.state.eventTime}
                    onChange={this.handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa" />
            </FormGroup>
            {showExtras &&
                <FormGroup className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Location:</span>
                    </div>
                    <Input type="text" name="location" onChange={this.onChange} value={this.state.location === null ? '' : this.state.location} />
                </FormGroup>
            }
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Team:</span>
                </div>
                <Select
                    onChange={this.handleTeamChange}
                    className="basic-single"
                    select={{ width: '100%' }}
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    name="teamName"
                    options={this.state.teamChoices}
                    value={this.state.selectedTeam}
                />

                {/*
                <Input type="text" name="teamName" onChange={this.onChange} value={this.state.teamName === null ? '' : this.state.teamName} />
                */}
            </FormGroup>

            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Result:</span>
                </div>
                <Input type="text" name="result" onChange={this.onChange} value={this.state.result === null ? '' : this.state.result} />
            </FormGroup>

            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Score:</span>
                </div>
                <Input type="text" name="score" onChange={this.onChange} value={this.state.score === null ? '' : this.state.score} />
            </FormGroup>
            <Button color="success" type="submit">Save</Button>
        </Form>;
    }
}

export default ConferenceForm;