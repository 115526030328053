import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';
import ConferenceModal from './form/ConferenceModal';

class ConferenceTable extends Component {

    state = {
        isLoggedIn: false,
        userName: null
    }

    componentDidMount() {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
    }

    deleteEvent = id => {
        let confirmDeletion = window.confirm('Are you sure you want to delete this event?');
        if (confirmDeletion) {
            fetch(`api/Events/${id}`, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    this.props.deleteEventFromState(id);
                })
                .catch(err => console.log(err));
        }
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn || false;
        const userName = this.state.userName || '';
        const events = this.props.events;
        const listType = this.props.listType;

        let showExtras = false;
        switch (listType) {
            case 'Conference':
                showExtras = true;
                break;
            case 'Lichess':
            default:
                showExtras = false;
                break;
        }

        let statusDisplay = "Loading...";
        if (events.length === 0) {
            statusDisplay = "No results entered";
        }

        return <Table striped hover>
            <thead className="thead-dark">
                <tr>
                    <th style={{ display: 'none' }}>ID</th>
                    <th>Team</th>
                    <th>Opponent</th>
                    <th>Date</th>
                    <th>Time</th>
                    {showExtras &&
                        <th>Location</th>
                    }
                    <th>Result</th>
                    <th>Score</th>
                    {isLoggedIn && <th>Actions</th>}
                </tr>
            </thead>

            <tbody>
                {!events || events.length <= 0 ?
                    <tr>
                        <td colSpan="8" align="center"><b><i>{statusDisplay}</i></b></td>
                    </tr>
                    : events.map(event => (
                        <tr key={event.id}>
                            <td style={{ display: 'none' }}>{event.id}</td>
                            <td>{event.teamName}</td>
                            <td>{event.eventName}</td>
                            <td>{event.eventDateFormatted}</td>
                            <td>{event.eventTimeFormatted}</td>
                            {showExtras &&
                                <td>{event.location}</td>
                            }
                            <td>{event.result}</td>
                            <td>{event.score}</td>

                            {isLoggedIn &&
                                <td>
                                    <div>
                                        <ConferenceModal
                                            userName={userName}
                                            isNew={false}
                                            listType={this.props.listType}
                                            event={event}
                                            updateState={this.props.updateState} />
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                            className="btn-sm"
                                            color="danger"
                                            onClick={() => this.deleteEvent(event.id)}>
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
            </tbody>
        </Table>;
    }
}

export default ConferenceTable;
