import React from 'react';
import MyDatePicker from 'react-datepicker';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';

import 'react-datepicker/dist/react-datepicker.css';

class BlogItemForm extends React.Component {
    state = {
        id: 0,
        itemDate: '',
        itemTitle: '',
        itemContent: '',
        userName: '',
        loading: true
    }

    componentDidMount() {
        if (this.props.blogitem) {
            const { id, itemTitle, itemContent } = this.props.blogitem
            this.setState({ id, itemTitle, itemContent });

            this.setState({ itemDate: Date.parse(this.props.blogitem.itemDate) })
            this.setState({ userName: this.props.userName });
        } else {
            //If this is a new entry, put in today's date by default
            var tmpDate = new Date();
            tmpDate.setHours = 0;
            this.setState({ itemDate: tmpDate })
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleDateChange = date => {
        console.log(date);
        this.setState({ itemDate: date });
    };

    handleEditorLoaded = () => {
        this.setState({ loading: false });
    }

    handleEditorChange = (e) => {
        this.setState({ itemContent: e.target.getContent() });
    }

    submitNew = e => {
        console.log("new:", this.state.itemDate);

        e.preventDefault();
        fetch(`api/Blogitems`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                itemDateUnixTimestamp: this.state.itemDate,
                itemTitle: this.state.itemTitle,
                itemContent: this.state.itemContent,
                userName: this.state.userName
            })
        })
            .then(res => res.json())
            .then(blogitem => {
                this.props.addToState(blogitem);
                this.props.toggle();
            })
            .catch(err => console.log(err));
    }

    submitEdit = e => {
        e.preventDefault();
        fetch(`api/Blogitems/${this.state.id}`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                itemDateUnixTimestamp: this.state.itemDate.toString(),
                itemTitle: this.state.itemTitle,
                itemContent: this.state.itemContent,
                userName: this.state.userName
            })
        })
            .then(() => {
                this.props.toggle();
                this.props.updateState(this.state.id);
            })
            .catch(err => console.log(err));
    }


    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : '';

        return <Form onSubmit={this.props.blogitem ? this.submitEdit : this.submitNew} >
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Title:</span>
                </div>
                <Input required type="text" name="itemTitle" onChange={this.onChange} value={this.state.itemTitle === '' ? '' : this.state.itemTitle} />
            </FormGroup>
            <FormGroup className="input-group">
                {contents}
                <Editor
                    apiKey="4vfs7j9po36evye6cc7b2zhu1v87mn0oeu57myfticnmiytc"
                    initialValue={this.state.itemContent}
                    onInit={this.handleEditorLoaded}
                    init={{
                        height: 300,
                        menubar: 'file edit view insert format tools table help',
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount emoticons'
                        ],
                        toolbar: 'undo redo | formatselect | fontselect fontsizeselect | bold italic | forecolor backcolor |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | link image | help',
                    }}
                    onChange={this.handleEditorChange}
                    required
                />
            </FormGroup>
            <FormGroup className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Date:</span>
                </div>
                <div>
                    <MyDatePicker name="itemDate"
                        className="form-control"
                        selected={this.state.itemDate}
                        onChange={this.handleDateChange} required />
                </div>
            </FormGroup>
            <Button color="success" type="submit">Save</Button>
        </Form>;
    }
}

export default BlogItemForm;