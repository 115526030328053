import React, { Component } from 'react';
import { Container, ListGroup, ListGroupItem, Col, Row, Button } from 'reactstrap';
import BlogItemModal from './form/BlogItemModal';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from "react-helmet";

export class Home extends Component {

    state = {
        blogitems: [],
        userName: null,
        isLoggedIn: false,
        year: ''
    }

    componentDidMount() {
        this.logPageHit();

        this.getBlogitems();
    }

    logPageHit() {
        var pagename = 'Home';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getBlogitems = () => {
        var params = this.props.location.search;
        var thisyear = "";
        if (params.includes("?year=")) {
            thisyear = params.replace("?year=", "");
        }
        console.log(thisyear);
        this.setState({ year: thisyear })
        console.log("This year: " + thisyear);
        fetch('api/Blogitems?year=' + thisyear)
            .then(res => res.json())
            .then(res => this.setState({ blogitems: res }))
            .catch(err => console.log(err));
        //console.log(this.state.blogitems);
    }

    addToState = blogitem => {
        this.updateState();

        //this.setState(previous => ({
        //    blogitems: [...previous.blogitems, blogitem]
        //}));
    }

    updateState = () => {
        this.getBlogitems();
    }

    deleteFromState = id => {
        //const updated = this.state.blogitems.filter(blogitem => blogitem.id !== id);
        //this.setState({ blogitems: updated })

        this.updateState();
    }

    deleteItem = id => {
        let confirmDeletion = window.confirm('Are you sure you want to delete this entry?');
        if (confirmDeletion) {
            fetch(`api/Blogitems/${id}`, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    this.deleteFromState(id);
                })
                .catch(err => console.log(err));
        }
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn || false;
        const userName = this.state.userName || '';
        const year = this.state.year;

        let titleText = "Recent Notes & Highlights";
        if (year !== "") {
            titleText = year + " Notes & Highlights"
        }

        return (
            <Container>
                <Helmet>
                    <title>Homepage for the Eastview High School chess team</title>
                </Helmet>
                <Row>
                    <Col>
                        <h3>{titleText}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ListGroup>
                            {this.state.blogitems.map(blogitem =>
                                <ListGroupItem className="flex-column align-items-start" key={blogitem.id}>
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{blogitem.itemTitle}</h5>
                                        <small>{blogitem.itemDateFormatted}</small>
                                    </div>
                                    <div className="mb-1 mt-2" dangerouslySetInnerHTML={{ __html: blogitem.itemContent }}>
                                    </div>

                                    {isLoggedIn &&
                                        <div className="text-right">
                                            <BlogItemModal
                                                userName={userName}
                                                isNew={false}
                                            blogitem={blogitem}
                                            updateState={this.updateState} />

                                            &nbsp;&nbsp;
    
                                        <Button color="danger" className="btn-sm" onClick={() => this.deleteItem(blogitem.id)}>Delete</Button>
                                        </div>
                                    }
                                </ListGroupItem>
                            )}
                        </ListGroup>
                    </Col>
                </Row>

                {isLoggedIn &&
                    <Row>
                        <Col>
                            <BlogItemModal isNew={true} addToState={this.addToState} />
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}
