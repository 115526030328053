import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

const columns = [
    { name: 'id', header: 'ID', type:'number', maxWidth: 75, defaultFlex: 3, editable:false },
    { name: 'title', header: 'Title', defaultFlex: 2 },
    { name: 'eventDate', header: 'Date', maxWidth: 150, defaultFlex: 1 },
    { name: 'eventTime', header: 'Time', maxWidth: 150, defaultFlex: 1 },
    { name: 'textColor', header: 'Text Color', maxWidth: 150, defaultFlex: 1 },
    { name: 'backgroundColor', header: 'Bkgd Color', maxWidth: 150, defaultFlex: 1 }
]

const gridStyle = { minHeight: 550 }


export class CalendarList extends Component {

    state = {
        isLoggedIn: false,
        items: []
    }

    componentDidMount() {
        this.logPageHit();
        this.getCalendarItems();
    }

    logPageHit() {
        var pagename = 'Calendar Items List';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getCalendarItems = () => {
        var url = "api/Admin/CalendarList";
        console.log(url);
        fetch(url)
            .then(res => res.json())
            .then(res => this.setState({ items: res }))
            .catch(err => console.log(err));
    }

    addEvent = event => {
        this.updateState(event.id);
    }

    updateState = (id) => {
        //console.log("updating state... ", id);
        this.getEvents("");
    }

    deleteEventFromState = id => {
        const updated = this.state.events.filter(event => event.id !== id);
        this.setState({ events: updated })
    }

    onEditComplete = (value) => {
        //console.log(value);

        if (value != null && value.value != null) {
            var rowId = value.data.id;
            var newValue = value.value;
            var colName = value.columnId;
            //console.log(newValue + " : " + colName + " : " + rowId + " : " + value.columnIndex);

            var newArray = [...this.state.items];
            var elementsIndex = newArray.findIndex(element => element.id === rowId);
            //console.log(newArray[elementsIndex][colName]);
            newArray[elementsIndex][colName] = newValue;
            this.setState({ items: newArray });
        }
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn || false;

        return <Container style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Row>
                <Col>
                    <h3>Edit Calendar Items</h3>
                </Col>
            </Row>
            {isLoggedIn &&
                <Row>
                    <Col>
                        <ReactDataGrid
                            idProperty="ID"
                            columns={columns}
                            dataSource={this.state.items}
                            style={gridStyle}
                            pagination="local"
                            defaultLimit={10}
                            onEditComplete={this.onEditComplete}
                            editable={true}
                        />
                    </Col>
                </Row>
            }

            {!isLoggedIn &&
                <Row>
                    <Col>
                        <h5 style={{ fontStyle: "italic", paddingType: "10px" }}>You do not have permissions to view this content</h5>
                    </Col>
                </Row>
            }
        </Container>;
    }
}
