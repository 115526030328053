import React, { Component } from 'react';
import ReactGA from 'react-ga';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from "react-helmet";

import '../styles/calendar-daygrid-main.min.css';
import '../styles/calendar-main.min.css';

export class Calendar extends Component {

    state = {
        events: []
    }

    componentDidMount() {
        this.logPageHit();
        this.getAllItems();
    }

    logPageHit() {
        var pagename = 'Calendar';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    getAllItems = () => {
        var url = 'api/CalendarItems/';
        //console.log(url);
        fetch(url)
            .then(res => res.json())
            .then(res => this.setState({ events: res }))
            .catch(err => console.log(err));
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Calendar and schedule for the Eastview High School chess team</title>
                </Helmet>
                <h3>Events Calendar</h3>
                <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin]}
                    events={this.state.events}
                />
            </div>
        );
    }
}
