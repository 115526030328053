
export const userService = {
    login,
    logout
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/users/authenticate`, requestOptions)
        .then(res => res.json())
        .then(res => handleResponse(res))
        .then(res => { return res });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function handleResponse(response) {
    console.log("Response: ", response);

    if (response === null) {
        logout();
        return "error";
    } else {
        localStorage.setItem('user', JSON.stringify(response.username));
        return response;
    }
}