import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import BlogItemForm from './BlogItemForm';

class BlogItemModal extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    }

    render() {
        const isNew = this.props.isNew;

        let title = 'Edit Entry';
        let button = '';
        if (isNew) {
            title = 'Add Entry';

            button = <Button
                color="success"
                className="btn-sm mt-2"
                onClick={this.toggle}
                style={{ minWidth: "100px" }}>Add</Button>;
        } else {
            button = <Button
                color="primary"
                className="btn-sm"
                onClick={this.toggle}>Edit</Button>;
        }

        return <Fragment>
            {button}
            <Modal
                fade
                isOpen={this.state.modal}
                toggle={this.toggle}
                className="{this.props.className} modal-lg">
                <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                                
                <ModalBody>
                    <BlogItemForm
                        addToState={this.props.addToState}
                        updateState={this.props.updateState}
                        toggle={this.toggle}
                        userName={this.props.userName}
                        blogitem={this.props.blogitem} />
                </ModalBody>
            </Modal>
        </Fragment>;
    }
}

export default BlogItemModal;