import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from "react-helmet";

export class About extends Component {

    state = {
        isLoggedIn: false
    }

    componentDidMount() {
        this.logPageHit();
    }

    logPageHit() {
        var pagename = 'About';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>About the Eastview High School chess team</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-4">
                        <img src="/dettmer_headshot2.png" alt="Bob Dettmer" style={{ border: '1px solid black' }} />
                        <br />
                        Mr. Bob Dettmer
                        <br />
                        <span className="em small">Robert.Dettmer@district196.org</span>

                    </div>
                    <div className="col-sm-8 pt-4 pl-4">
                        <p>
                            The Chess Team is open to all Eastview students, regardless of grade or level of skill.
                            The advisor is Mr. Dettmer; this is his 24th year of coaching.
                            It is one of the few teams at Eastview that has a fall, winter, and spring season.
                        </p><p>
                            We begin the first week of school with an information meeting.
                            In September and October, we play a 10-game schedule with other schools in our league: Eagan, Shakopee, Apple Valley, and Northfield.
                            Most matches are on Mondays after school from 3:15 to 5:15 p.m.
                            There are typically two all-day Saturday tournaments (10/12/24 at Northfield High School and 11/9/24 at Eastview High School) involving the teams from our division; one is a team competition, and the other is an individual tournament.
                        </p><p>
                            The fall season ends the first week of November followed by three months of an online league.  Once February arrives, we resume our twice a week practices in preparation for the South Suburban Championship (2/22/25 at Apple Valley High School) and the High School State Tournament (Mar 8 & 9, 2025 at St. Thomas University) which occur in March.
                            In the month of May, we meet casually on Fridays to have fun and sharpen our skills.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
