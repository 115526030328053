import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'reactstrap';
import { GOOGLE_ANALYTICS_API } from './constants';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

export class History extends Component {
    componentDidMount() {
        this.logPageHit();
    }

    logPageHit() {
        var pagename = 'History';
        var user = JSON.parse(localStorage.getItem('user'));
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        return (
            <Container>
                <Helmet>
                    <title>Archived links, history, past seasons for the Eastview High School chess team</title>
                </Helmet>
                <Row>
                    <Col>
                        <h3>Past Seasons</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="link-margin">
                            <h4>2023-24 Season (State Champions!)</h4>
                        </div>
                        <div className="link-margin">
                            <ul>
                                <li><Link to={`/Home?year=2023`}>Highlights & News</Link></li>
                                <li><Link to={`/Conference?year=2023`}>Conference Results</Link></li>
                                <li><Link to={`/Lichess?year=2023`}>MHSCL Online Results</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="link-margin">
                            <h4>2022-23 Season (State Champions!)</h4>
                        </div>
                        <div className="link-margin">
                            <ul>
                                <li><Link to={`/Home?year=2022`}>Highlights & News</Link></li>
                                <li><Link to={`/Conference?year=2022`}>Conference Results</Link></li>
                                <li><Link to={`/Lichess?year=2022`}>MHSCL Online Results</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="link-margin">
                            <h4>2021-22 Season</h4>
                        </div>
                        <div className="link-margin">
                            <ul>
                                <li><Link to={`/Home?year=2021`}>Highlights & News</Link></li>
                                <li><Link to={`/Conference?year=2021`}>Conference Results</Link></li>
                                <li><Link to={`/Lichess?year=2021`}>MHSCL Online Results</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="link-margin">
                            <h4>2020-21 Season</h4>
                        </div>
                        <div className="link-margin">
                            <ul>
                                <li><Link to={`/Home?year=2020`}>Highlights & News</Link></li>
                                <li><Link to={`/Conference?year=2020`}>Conference Results</Link></li>
                                <li><Link to={`/Lichess?year=2020`}>MHSCL Online Results</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="link-margin">
                            <h4>2019-20 Season</h4>
                        </div>
                        <div className="link-margin">
                            <ul>
                                <li><Link to={`/Home?year=2019`}>Highlights & News</Link></li>
                                <li><Link to={`/Conference?year=2019`}>Conference Results</Link></li>
                                <li><Link to={`/Lichess?year=2019`}>MHSCL Online Results</Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
