import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    state = {
        isLoggedIn: false
    }

    componentDidMount() {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
    }
    
    updateLoggedInStatus = () => {
        var user = JSON.parse(localStorage.getItem('user'));
        if (user != null) {
            this.setState({ isLoggedIn: true });
        }
    }

    render() {
        const isLoggedIn = this.state.isLoggedIn || false;
        return (
            <div>
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
                <br />
                <hr className="m-3" />

                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" style={{ background: '#eee' }} light>
                    <Container>
                        <div className="navbar-collapse justify-content-end">
                            <ul className="navbar-nav pull-right">
                                {/*   <LoginMenu>
                                </LoginMenu>
                                */}
                                {!isLoggedIn &&
                                    <NavLink to="/loginpage">Log In</NavLink>
                                }
                                {isLoggedIn &&
                                    <NavLink to="/loginpage">Logout</NavLink>
                        }
                                </ul>
                        </div>
                    </Container>
                </Navbar>

            </div>
        );
    }
}
