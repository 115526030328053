import React, { Component } from 'react';
import { Route } from 'react-router';
import { About } from './components/About';
import { Conference } from './components/Conference';
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import { Lichess } from './components/Lichess';
import { Calendar } from './components/Calendar';
import { History } from './components/History';
import { LoginPage } from './components/LoginPage';
import { CalendarList } from './components/CalendarList';

import './styles/custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/home' component={Home} />
                <Route path='/calendar' component={Calendar} />
                <Route path='/conference' component={Conference} />
                <Route path='/lichess' component={Lichess} />
                <Route path='/history' component={History} />
                <Route path='/about' component={About} />
                <Route path='/loginpage' component={LoginPage} />
                <Route path='/calendarlist' component={CalendarList} />
            </Layout>
        );
    }
}
